<template>
	<svg
		width="16"
		height="14"
		viewBox="0 0 16 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8 9C6.8402 9 5.9 9.89543 5.9 11C5.9 12.1046 6.8402 13 8 13C9.1598 13 10.1 12.1046 10.1 11C10.1 9.89543 9.1598 9 8 9ZM8 9V5.66667M15 7.66667C15 3.98477 11.866 1 8 1C4.134 1 1 3.98477 1 7.66667"
			stroke="#6A69F5"
			stroke-width="1.5"
			stroke-linecap="round"
		/>
	</svg>
</template>
