<template>
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M6.399 8.70907H2.45964C2.45964 6.76124 4.22335 5.18225 6.399 5.18225V8.70907ZM6.399 8.70907L6.39835 13.6464C4.16472 13.6464 2.24123 12.4577 1.37417 10.7482C1.02504 10.0599 0.850475 9.71581 1.16199 9.21237C1.4735 8.70893 1.98584 8.70893 3.01051 8.70893L6.399 8.70907ZM6.399 8.70907L13.4894 8.70893C13.4894 11.5598 11.3902 13.9718 8.50019 14.7737C7.65963 15.0069 7.23936 15.1235 6.81894 14.8059C6.39849 14.4884 6.39849 13.9726 6.39849 12.9411L6.399 8.70907ZM6.399 8.70907L6.39849 3.06609C6.39849 2.03746 6.39849 1.52314 6.79773 1.20548C7.19701 0.887808 7.63436 0.985773 8.50901 1.1817C11.5267 1.8577 13.9174 3.95009 14.7486 6.62404C15.0025 7.44059 15.1294 7.84883 14.8105 8.27891C14.4917 8.70893 13.9738 8.70893 12.9378 8.70893L6.399 8.70907Z"
			stroke="#999999"
			stroke-width="1.5"
		/>
	</svg>
</template>
