<script setup>
import { router } from '@inertiajs/vue3';
import { ref, provide, inject, onMounted, computed, watch } from 'vue';

const props = defineProps({
	title: {
		type: String,
		required: true,
	},
	titleClass: {
		type: String,
	},
});

const open = ref(false);
const active = ref(false);

const nav_links = ref([]);

// Provide the nav_links to children, to fill the nav_links
// NavLink automatically fill nav_links
// With this pattern, no need to set anything, when adding NavLink inside the NavDropDown

provide('nav_links', nav_links);

// Filling nav_links ref for NavDropDown - Automatically (Provider, injector pattern)

const parent_nav_links = inject('nav_links', null);

const is_root_menu = computed(() => parent_nav_links === null);

provide('is_under_nav_dropdown', { is_root_menu });

const menu_list_container_dom = ref(null);
const menu_list_container_height = ref(0);

// Setting menu height dynamically with it's content for a smooth full animation
// (Before it was half animating than making a sluggish jump)
watch(open, () => {
	if (open.value) {
		menu_list_container_height.value = `${menu_list_container_dom.value.scrollHeight}px`;
		setTimeout(() => {
			menu_list_container_height.value = 'inherit';
		}, 300);
	} else {
		menu_list_container_height.value = `${menu_list_container_dom.value.scrollHeight}px`;
		setTimeout(() => {
			menu_list_container_height.value = 0;
		}, 0);
	}
});

onMounted(() => {
	if (parent_nav_links) {
		parent_nav_links.value.push(...(nav_links.value || []));
	}
});

router.on('navigate', (event) => {
	// Remove the query string from the event detail page url
	let current_url = event.detail.page.url.split('?')[0];
	// Find the object key in links where the event detail page url is found in its array of value
	const includes_current = nav_links.value.includes(current_url);
	active.value = includes_current;
	open.value = includes_current;
});
</script>

<template>
	<li
		class="menu nav-item bg-[#292929] bg-opacity-0"
		:class="{
			'rounded-lg py-1 pl-3 pr-2.5': is_root_menu,
			'root-menu-open bg-opacity-100 py-3': is_root_menu && open,
			'bg-opacity-0 transition-all duration-300': is_root_menu && !open,
		}"
	>
		<a
			class="cursor-pointer select-none items-center justify-between transition-colors duration-300"
			:class="{ active: active }"
			@click.prevent="open = !open"
		>
			<div class="flex items-center">
				<slot name="icon" :active="active" />
				<span class="ml-2 font-bold" :class="titleClass">{{ title }}</span>
			</div>
			<div class="dropdown-icon flex size-4 items-center justify-center">
				<i
					class="size-7 text-lg"
					:class="{
						'ri-arrow-right-s-line': !open,
						'ri-arrow-down-s-line': open,
					}"
				></i>
			</div>
		</a>
		<!-- Dropdown collapse content with transition -->
		<ul
			class="sub-menu flex flex-col gap-1 overflow-hidden transition-all duration-300"
			:class="{ 'ease-out': open, 'max-h-0 ease-in': !open }"
			:style="{
				maxHeight: menu_list_container_height,
			}"
			ref="menu_list_container_dom"
		>
			<slot />
		</ul>
	</li>
</template>
