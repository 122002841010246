<template>
	<svg
		width="227"
		height="34"
		viewBox="0 0 227 34"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.54964 23.7897L8.87871 19.9234C7.79804 18.8046 7.08811 17.3259 6.96533 15.6625C6.6853 11.8686 9.56915 8.55332 13.3953 8.27091C17.2215 7.9885 20.5693 10.8466 20.8494 14.6419C21.1296 18.4372 18.2456 21.7511 14.4194 22.0335C12.7934 22.1535 11.2556 21.7058 9.99996 20.8604L6.65411 24.7433C6.38124 25.0599 5.94203 25.0923 5.62563 24.8192C5.30922 24.546 5.27677 24.1064 5.54964 23.7897ZM20.0729 14.6992C19.8247 11.3366 16.8571 8.80936 13.4619 9.05996C10.0666 9.31056 7.50233 12.2475 7.75042 15.6087C7.99851 18.9699 10.9662 21.4986 14.3615 21.248C17.7567 20.9974 20.3207 18.0563 20.0729 14.6992Z"
			fill="#408DDC"
		/>
		<path
			d="M23.0208 3.54562C23.0208 4.42079 23.0505 5.29739 23.0095 6.16971C22.9855 6.69112 23.1268 6.87156 23.6711 6.85451C24.9166 6.81473 26.1663 6.86729 27.4118 6.83035C27.9335 6.81473 27.9689 7.06193 27.9674 7.4711C27.966 7.89022 27.9674 8.17863 27.392 8.16584C25.6984 8.1289 24.0033 8.13885 22.3097 8.16158C21.8516 8.16726 21.7018 8.03229 21.706 7.56345C21.7258 5.29312 21.7315 3.02279 21.6975 0.753888C21.6891 0.185595 21.9563 0.178491 22.3804 0.164284C22.8469 0.150077 23.0561 0.25379 23.0335 0.780881C22.9925 1.70152 23.0222 2.62499 23.0222 3.54704H23.0194L23.0208 3.54562Z"
			fill="url(#paint0_linear_1_263)"
		/>
		<path
			d="M6.48145 4.15659C6.48145 5.31449 6.46872 6.47238 6.48852 7.63028C6.49559 8.0295 6.36411 8.16163 5.96544 8.15879C4.22513 8.14174 2.48483 8.1318 0.744532 8.16163C0.163489 8.17158 0.26245 7.82066 0.244072 7.47116C0.221452 7.06483 0.269519 6.81336 0.794013 6.82899C1.99286 6.86592 3.19453 6.79773 4.39054 6.8574C5.07337 6.8915 5.20344 6.64145 5.19071 6.01917C5.15113 4.27167 5.19495 2.52133 5.16244 0.77383C5.15254 0.241056 5.36319 0.152971 5.82265 0.162916C6.25101 0.17144 6.52245 0.188489 6.50124 0.75394C6.45741 1.88626 6.48852 3.02143 6.48852 4.15659H6.48145Z"
			fill="url(#paint1_linear_1_263)"
		/>
		<path
			d="M15.0784 27.083C15.0784 25.783 15.0982 24.483 15.0685 23.1831C15.0572 22.6858 15.1505 22.467 15.716 22.4755C16.2391 22.4841 16.396 22.6219 16.3903 23.1575C16.3635 25.7105 16.3804 28.2622 16.3762 30.8152C16.3762 31.1974 16.4073 31.5256 16.6886 31.8524C17.1128 32.3453 17.0209 33.1125 16.5798 33.5274C16.1288 33.9508 15.3611 33.9593 14.9003 33.5459C14.4535 33.1438 14.3433 32.361 14.7575 31.8737C15.0346 31.5483 15.0855 31.2258 15.0826 30.8394C15.0727 29.5863 15.0798 28.3346 15.0798 27.0815L15.0784 27.083Z"
			fill="url(#paint2_linear_1_263)"
		/>
		<path
			d="M9.84335 26.32C9.84335 27.0986 9.83911 27.8771 9.84477 28.6571C9.84901 29.2581 9.80235 29.8306 10.2307 30.3875C10.6167 30.8891 10.4145 31.4773 9.931 31.8978C9.51395 32.2601 8.79578 32.2459 8.36742 31.8935C7.89099 31.5014 7.71286 30.7953 8.11153 30.3108C8.47204 29.8718 8.46921 29.42 8.46921 28.9299C8.46921 26.9948 8.49324 25.0584 8.45507 23.1248C8.44376 22.5394 8.68692 22.4883 9.16193 22.4826C9.6525 22.4769 9.88435 22.5622 9.8589 23.1347C9.81225 24.1946 9.84477 25.2587 9.84477 26.32H9.84335Z"
			fill="url(#paint3_linear_1_263)"
		/>
		<path
			d="M13.1079 28.1855C13.1079 29.9088 13.0994 31.6336 13.1136 33.3569C13.1178 33.7959 13.0358 33.9096 12.6117 33.6652C12.0844 33.3612 11.8031 33.0273 11.8144 32.3383C11.8624 29.2922 11.8426 26.2462 11.8285 23.2001C11.8257 22.737 11.8582 22.4784 12.4505 22.4713C13.0698 22.4642 13.1221 22.7284 13.115 23.2257C13.0924 24.878 13.1065 26.5317 13.1065 28.1841L13.1079 28.1855Z"
			fill="url(#paint4_linear_1_263)"
		/>
		<path
			d="M19.0427 7.98404C18.4659 8.11759 18.3231 7.85901 18.3386 7.30493C18.3754 6.03195 18.35 4.75614 18.3485 3.48174C18.3485 2.87793 18.391 2.30111 17.9414 1.76266C17.5809 1.32933 17.8 0.593396 18.2439 0.241055C18.6652 -0.094238 19.4937 -0.0743478 19.8867 0.279414C20.3179 0.667274 20.4691 1.42452 20.0818 1.88484C19.7496 2.2798 19.7439 2.68187 19.7439 3.12798C19.7467 4.5203 19.7199 5.91403 19.7552 7.30493C19.7693 7.8647 19.6152 8.1119 19.0441 7.98404H19.0427Z"
			fill="url(#paint5_linear_1_263)"
		/>
		<path
			d="M18.3682 31.7585C18.3682 28.6741 18.3739 25.7972 18.364 22.9188C18.3626 22.3476 18.7613 22.5138 19.0539 22.5025C19.3649 22.4911 19.7254 22.3917 19.7212 22.9457C19.7056 25.3795 19.7325 27.8132 19.7028 30.2469C19.6943 31.007 18.9224 31.1689 18.3668 31.7585H18.3682Z"
			fill="url(#paint6_linear_1_263)"
		/>
		<path
			d="M12.4545 7.98406C11.8876 8.11051 11.783 7.82778 11.7915 7.32484C11.8169 5.86149 11.7943 4.39814 11.7999 2.93621C11.7999 2.59807 11.7505 2.31535 11.5172 2.02978C11.0803 1.49416 11.1553 0.729811 11.6162 0.30075C12.0374 -0.0927924 12.8744 -0.101317 13.2971 0.282281C13.7961 0.735494 13.864 1.49274 13.389 2.06246C13.167 2.32955 13.1274 2.59097 13.1274 2.90211C13.1317 4.36546 13.1105 5.82881 13.1373 7.29075C13.1472 7.81358 13.0553 8.1304 12.4531 7.98548L12.4545 7.98406Z"
			fill="url(#paint7_linear_1_263)"
		/>
		<path
			d="M4.50242 11.5358C3.98641 11.5358 3.46898 11.5443 2.95297 11.533C2.5953 11.5259 2.29417 11.5727 1.98739 11.8285C1.46714 12.2646 0.775826 12.1751 0.334742 11.7049C-0.0950319 11.2446 -0.11341 10.5029 0.293744 10.0213C0.695243 9.54678 1.43321 9.41181 1.94498 9.82525C2.29559 10.108 2.64336 10.1478 3.04628 10.1449C4.1504 10.1364 5.25452 10.1733 6.35723 10.1293C6.9722 10.1051 6.88738 10.4717 6.9001 10.8524C6.91424 11.2644 6.9199 11.5955 6.33461 11.5472C5.72812 11.4974 5.11456 11.5358 4.50383 11.5358H4.50242Z"
			fill="url(#paint8_linear_1_263)"
		/>
		<path
			d="M9.83919 4.0855C9.83919 5.19509 9.81091 6.3061 9.8505 7.41427C9.87029 7.96835 9.61017 8.02518 9.17191 8.00813C8.78031 7.99251 8.45091 8.04933 8.45939 7.44979C8.48908 5.20787 8.48766 2.96454 8.45656 0.722625C8.44808 0.114552 8.78879 0.18843 9.17332 0.171381C9.60734 0.151491 9.87595 0.199796 9.85474 0.759564C9.81374 1.86773 9.84202 2.97875 9.84202 4.08834H9.83919V4.0855Z"
			fill="url(#paint9_linear_1_263)"
		/>
		<path
			d="M4.56607 18.1934C4.0953 18.1934 3.62453 18.2047 3.15517 18.1905C2.81587 18.1806 2.53596 18.2545 2.24614 18.4676C1.66086 18.8952 0.94551 18.7929 0.532701 18.2942C0.121305 17.7956 0.155235 17.088 0.613283 16.6206C1.09254 16.1319 1.71882 16.0921 2.31966 16.5794C2.56989 16.7826 2.81305 16.811 3.09721 16.8096C4.17871 16.8067 5.26304 16.8422 6.34313 16.7954C6.94538 16.7698 6.89307 17.1093 6.89873 17.5043C6.90438 17.9149 6.91852 18.253 6.33041 18.2047C5.74654 18.1564 5.1556 18.1948 4.56749 18.1948L4.56607 18.1934Z"
			fill="url(#paint10_linear_1_263)"
		/>
		<path
			d="M23.6246 13.5064C24.1194 13.5064 24.6142 13.4993 25.109 13.5092C25.5133 13.5163 25.8653 13.4751 26.2145 13.1825C26.7221 12.7562 27.542 12.8926 27.9308 13.3757C28.3224 13.863 28.3026 14.5322 27.8813 15.001C27.4346 15.4983 26.7164 15.5679 26.1735 15.1047C25.912 14.8802 25.6533 14.8291 25.338 14.8305C24.1844 14.8376 23.0294 14.8035 21.8758 14.8461C21.2919 14.8674 21.3075 14.5563 21.3046 14.1443C21.3032 13.7408 21.3301 13.4595 21.8574 13.4979C22.4427 13.5405 23.035 13.5078 23.6232 13.5078L23.6246 13.5064Z"
			fill="url(#paint11_linear_1_263)"
		/>
		<path
			d="M16.3735 4.11969C16.3735 5.20655 16.348 6.29483 16.3848 7.38169C16.4017 7.88747 16.2335 8.02954 15.7458 8.01392C15.3103 8.00113 15.0644 7.97129 15.0714 7.41721C15.1011 5.19519 15.1011 2.97316 15.0672 0.75256C15.0587 0.187109 15.3202 0.177164 15.7458 0.162956C16.2264 0.145908 16.406 0.275194 16.389 0.788078C16.3508 1.89767 16.3777 3.0101 16.3777 4.11969H16.3735Z"
			fill="url(#paint12_linear_1_263)"
		/>
		<path
			d="M23.3248 21.4894C22.8625 21.4894 22.509 21.4894 22.157 21.4894C21.2621 21.4894 21.2833 21.4894 21.3088 20.5887C21.3201 20.2051 21.4643 20.1014 21.8135 20.1113C22.4483 20.1284 23.083 20.1184 23.7178 20.1156C24.2946 20.1128 24.8474 20.1639 25.3747 19.7405C25.8172 19.3853 26.6541 19.6382 26.9552 20.073C27.3016 20.5702 27.2465 21.2934 26.8336 21.7025C26.4025 22.1302 25.6914 22.2282 25.2319 21.8063C24.6028 21.228 23.8775 21.6329 23.3248 21.4894Z"
			fill="url(#paint13_linear_1_263)"
		/>
		<path
			d="M24.5632 11.5273C23.6457 11.5273 22.7253 11.4988 21.8092 11.5372C21.2254 11.5614 21.313 11.1948 21.3116 10.8439C21.3116 10.4915 21.231 10.1406 21.8106 10.1491C23.6937 10.1761 25.5768 10.1776 27.4599 10.1491C28.0395 10.1406 27.9575 10.493 27.9575 10.8453C27.9575 11.1962 28.041 11.5599 27.4585 11.5372C26.4943 11.4988 25.5288 11.5273 24.5632 11.5273Z"
			fill="url(#paint14_linear_1_263)"
		/>
		<path
			d="M25.4709 23.4843C25.1825 24.5214 24.6439 25.04 23.6104 24.851C23.1609 24.7686 22.9375 24.9675 23.011 25.4364C23.0845 25.9052 22.8315 26.4124 23.2075 26.85C23.3107 26.9708 23.134 27.1029 23.0478 27.2038C22.6689 27.6499 22.2815 28.0917 21.9083 28.5236C21.6326 28.4213 21.733 28.2267 21.7316 28.0875C21.7231 26.742 21.7316 25.398 21.7231 24.0526C21.7217 23.7144 21.7302 23.463 22.1911 23.4743C23.2683 23.5013 24.3456 23.4829 25.4695 23.4829L25.4709 23.4843Z"
			fill="url(#paint15_linear_1_263)"
		/>
		<path
			d="M3.22294 2.53697C3.22294 3.15214 3.20739 3.7659 3.22718 4.37965C3.23849 4.73626 3.13812 4.89254 2.75358 4.87833C2.07217 4.85276 1.38792 4.84565 0.707916 4.87833C0.0943573 4.90817 0.264005 4.48053 0.248454 4.16228C0.230075 3.80852 0.169285 3.47039 0.744673 3.46612C1.83042 3.45618 1.829 3.42208 1.829 2.31391C1.829 1.74704 1.84173 1.17875 1.82618 0.611876C1.80921 -0.0146664 2.26867 0.201285 2.57263 0.182815C2.87941 0.164346 3.28515 0.0634737 3.22294 0.621821C3.22011 0.644553 3.22294 0.668705 3.22294 0.692858C3.22294 1.30803 3.22294 1.92179 3.22294 2.53697Z"
			fill="url(#paint16_linear_1_263)"
		/>
		<path
			d="M26.3699 1.87342C26.3699 1.99134 26.3699 2.10926 26.3699 2.22718C26.3699 3.38792 26.3685 3.41776 27.5122 3.46748C28.1243 3.49448 27.942 3.89938 27.9533 4.21336C27.9646 4.52024 28.0763 4.90242 27.5164 4.87827C26.8364 4.84843 26.1522 4.85696 25.4708 4.87827C25.1145 4.88963 24.9703 4.7845 24.976 4.4009C24.9944 3.14924 25.0042 1.89615 24.9717 0.644489C24.9562 0.0307327 25.3619 0.189855 25.6814 0.178489C26.025 0.167123 26.4222 0.0804583 26.3756 0.668641C26.3445 1.06787 26.3699 1.47135 26.3699 1.87342Z"
			fill="url(#paint17_linear_1_263)"
		/>
		<path
			d="M2.72536 23.4828C3.84504 23.4828 4.90109 23.4985 5.95715 23.4757C6.35723 23.4672 6.48447 23.6022 6.47882 24.0014C6.46044 25.4875 6.47175 26.975 6.47175 28.5747C5.82284 28.13 5.29977 27.6058 5.06226 27.0006C4.93502 26.6766 5.16263 26.0956 5.2008 25.6224C5.25028 24.9874 5.06226 24.743 4.38791 24.77C3.23996 24.8183 3.223 24.7672 2.72536 23.4814V23.4828Z"
			fill="url(#paint18_linear_1_263)"
		/>
		<path
			d="M3.57199 14.8148C2.63044 14.8148 1.68749 14.7864 0.747356 14.8248C0.162072 14.8489 0.262447 14.4838 0.24831 14.1343C0.234172 13.772 0.239827 13.5078 0.745943 13.5149C2.62903 13.5404 4.51353 13.5433 6.39662 13.5149C6.98049 13.5063 6.88153 13.8573 6.89567 14.2053C6.9098 14.5676 6.90556 14.8433 6.39804 14.8248C5.4579 14.7893 4.51495 14.8148 3.5734 14.8148H3.57199Z"
			fill="url(#paint19_linear_1_263)"
		/>
		<path
			d="M24.5448 16.8124C25.4157 16.8124 26.2879 16.8081 27.1588 16.8152C27.4005 16.8167 27.7427 16.7357 27.6847 17.1647C27.6366 17.5256 27.8006 18.0868 27.204 18.0982C25.3676 18.1323 23.5297 18.1152 21.6933 18.1095C21.1236 18.1081 21.3554 17.6549 21.3243 17.375C21.2918 17.0951 21.2664 16.7996 21.7188 16.8081C22.6603 16.828 23.6033 16.8138 24.5448 16.8138V16.8124Z"
			fill="url(#paint20_linear_1_263)"
		/>
		<path
			d="M1.20982 20.1995C3.05191 20.1995 4.72435 20.2194 6.39396 20.1895C6.97925 20.1796 6.8718 20.5376 6.89018 20.8843C6.90998 21.2594 6.87887 21.5051 6.38124 21.4938C4.96892 21.4625 3.55519 21.4881 2.14146 21.481C1.28757 21.4767 1.5166 20.7138 1.2084 20.1995H1.20982Z"
			fill="url(#paint21_linear_1_263)"
		/>
		<path
			d="M11.0723 15.955C11.0723 15.955 11.7502 15.955 12.654 17.7165C12.654 17.7165 15.1661 13.1031 17.3992 12.1804"
			stroke="url(#paint22_linear_1_263)"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M51.7639 15.6168H44.5917V23.4269C44.5917 23.7498 44.5326 23.9599 44.409 24.0573C44.2855 24.1547 44.0491 24.2059 43.6945 24.2059H42.0613C41.7067 24.2059 41.4649 24.1547 41.3414 24.0573C41.2178 23.9599 41.1587 23.7498 41.1587 23.4269V5.87968C41.1587 5.55681 41.2178 5.3467 41.3414 5.24933C41.4649 5.15196 41.7013 5.10071 42.0613 5.10071H52.4999C52.8384 5.10071 53.0587 5.15708 53.1607 5.27495C53.2628 5.39282 53.3165 5.61831 53.3165 5.95655V7.13525C53.3165 7.47348 53.2628 7.7041 53.1607 7.81684C53.0587 7.93471 52.8384 7.99109 52.4999 7.99109H44.5917V12.7161H51.7639C52.1024 12.7161 52.3226 12.7725 52.4247 12.8904C52.5268 13.0082 52.5805 13.2337 52.5805 13.5771V14.7558C52.5805 15.094 52.5268 15.3247 52.4247 15.4374C52.3226 15.5553 52.1024 15.6116 51.7639 15.6116V15.6168Z"
			fill="white"
		/>
		<path
			d="M59.1095 11.081V12.3524C59.4163 11.7699 59.8792 11.2836 60.498 10.8936C61.1168 10.5035 61.7668 10.311 62.4584 10.311H63.0564C63.3268 10.311 63.5088 10.3617 63.6024 10.4579C63.6908 10.5542 63.7376 10.7517 63.7376 11.0557V12.4892C63.7376 12.7881 63.6908 12.9907 63.6024 13.0869C63.5088 13.1832 63.332 13.2338 63.0564 13.2338H62.292C61.3664 13.2338 60.6124 13.5276 60.03 14.1203C59.4475 14.713 59.1563 15.493 59.1563 16.4656V23.4408C59.1563 23.7397 59.1043 23.9474 58.9951 24.0487C58.8859 24.15 58.6519 24.2057 58.2879 24.2057H56.7903C56.4471 24.2057 56.2131 24.1551 56.0987 24.0487C55.9791 23.9423 55.9219 23.7397 55.9219 23.4408V11.081C55.9219 10.7821 55.9791 10.5744 56.0987 10.4731C56.2183 10.3668 56.4471 10.3161 56.7903 10.3161H58.2359C58.5999 10.3161 58.8339 10.3718 58.9431 10.4731C59.0523 10.5795 59.1043 10.7821 59.1043 11.081H59.1095Z"
			fill="white"
		/>
		<path
			d="M71.2734 15.4338C71.7086 15.4338 72.209 15.4501 72.7693 15.4774C73.3296 15.5046 73.8573 15.5482 74.3523 15.6081V14.8674C74.3523 13.7998 74.1456 13.0646 73.7376 12.6561C73.3296 12.2476 72.6986 12.0406 71.8446 12.0406C71.2571 12.0406 70.7729 12.1332 70.3921 12.3129C70.0114 12.4927 69.7448 12.8031 69.5925 13.2389C69.5163 13.4676 69.4239 13.6147 69.3096 13.68C69.1954 13.7454 68.9887 13.7781 68.6841 13.7781H66.8345C66.5299 13.7781 66.3232 13.7236 66.209 13.6201C66.0947 13.5166 66.0403 13.3587 66.0403 13.1517C66.0403 12.411 66.3341 11.7193 66.9216 11.0711C67.3948 10.5592 68.0204 10.1561 68.8037 9.8729C69.5816 9.58968 70.5989 9.44263 71.85 9.44263C73.3514 9.44263 74.5155 9.64415 75.3532 10.0417C76.1909 10.4393 76.7784 11.0276 77.1211 11.7955C77.4638 12.5635 77.6325 13.5112 77.6325 14.6332V20.1941C77.6325 21.0328 77.4693 21.8226 77.1483 22.5742C76.8274 23.3258 76.2671 23.9304 75.4838 24.3879C74.695 24.8454 73.5907 25.0741 72.1655 25.0741H71.3659C69.3532 25.0741 67.8681 24.6983 66.9107 23.9467C65.9533 23.1951 65.4746 21.986 65.4746 20.3084C65.4746 19.0557 65.7357 18.0754 66.2579 17.3728C66.7801 16.6702 67.4819 16.1691 68.3686 15.875C69.2498 15.5809 70.2235 15.4338 71.2897 15.4338H71.2734ZM71.442 18.0263C70.5499 18.0263 69.8808 18.1952 69.4347 18.5383C68.9887 18.8815 68.7657 19.4588 68.7657 20.2758C68.7657 21.0927 68.9833 21.6646 69.4184 21.986C69.8536 22.3073 70.4737 22.4707 71.2679 22.4707H71.7521C72.6823 22.4707 73.3459 22.242 73.7485 21.7844C74.1456 21.3269 74.3468 20.559 74.3468 19.4751V18.1897C73.7213 18.1135 73.1664 18.0645 72.6932 18.0481C72.2199 18.0318 71.801 18.0209 71.442 18.0209V18.0263Z"
			fill="white"
		/>
		<path
			d="M89.0268 23.895V23.3447C88.5645 23.895 87.9866 24.3195 87.2987 24.6234C86.6052 24.9274 85.8291 25.0741 84.9651 25.0741C83.369 25.0741 82.1856 24.6287 81.4041 23.743C80.6226 22.8573 80.2373 21.6415 80.2373 20.1059V11.1076C80.2373 10.7775 80.2978 10.5626 80.4244 10.463C80.551 10.3634 80.7932 10.311 81.1564 10.311H82.7415C83.1048 10.311 83.3524 10.3634 83.479 10.463C83.6056 10.5626 83.6662 10.7775 83.6662 11.1076V19.6133C83.6662 20.4571 83.8368 21.1017 84.1835 21.5471C84.5303 21.9978 85.1632 22.2179 86.0878 22.2179C87.0125 22.2179 87.6949 21.9454 88.2068 21.3952C88.7131 20.8449 88.9718 20.0955 88.9718 19.1469V11.1076C88.9718 10.7775 89.0323 10.5626 89.1589 10.463C89.2855 10.3634 89.5277 10.311 89.8909 10.311H91.476C91.8393 10.311 92.0869 10.3634 92.208 10.463C92.3346 10.5626 92.3952 10.7775 92.3952 11.1076V23.895C92.3952 24.2251 92.3346 24.44 92.208 24.5396C92.0814 24.6392 91.8393 24.6916 91.476 24.6916H89.946C89.5827 24.6916 89.3351 24.6392 89.2085 24.5396C89.0819 24.44 89.0213 24.2251 89.0213 23.895H89.0268Z"
			fill="white"
		/>
		<path
			d="M101.405 9.8866C102.891 9.8866 104.034 10.3016 104.839 11.1264V5.89923C104.839 5.58928 104.895 5.37389 105.013 5.26356C105.131 5.15324 105.357 5.10071 105.695 5.10071H107.171C107.53 5.10071 107.76 5.1585 107.868 5.26356C107.976 5.37389 108.027 5.58403 108.027 5.89923V18.9383C108.027 20.9031 107.509 22.4161 106.469 23.4773C105.433 24.5437 103.937 25.0743 101.989 25.0743C100.041 25.0743 98.5959 24.5437 97.5042 23.4773C96.4125 22.4161 95.8691 20.9556 95.8691 19.1011V16.4061C95.8691 14.3153 96.3407 12.7077 97.2941 11.5782C98.2423 10.4487 99.6108 9.8866 101.4 9.8866H101.405ZM99.0675 16.4324V18.5495C99.0675 21.0607 100.041 22.3163 101.994 22.3163C103.947 22.3163 104.895 21.0607 104.895 18.5495V16.4587C104.895 13.9265 103.927 12.6604 101.994 12.6604C100.062 12.6604 99.0675 13.916 99.0675 16.4271V16.4324Z"
			fill="white"
		/>
		<path
			d="M121.995 18.7403C122.016 18.4621 122.084 18.2749 122.199 18.1786C122.319 18.0876 122.539 18.0395 122.868 18.0395H124.511C124.856 18.0395 125.092 18.093 125.207 18.1946C125.327 18.2963 125.385 18.4996 125.385 18.7991C125.385 19.7888 125.264 20.618 125.029 21.292C124.794 21.9661 124.401 22.592 123.852 23.1698C122.649 24.443 120.818 25.0742 118.375 25.0742C115.325 25.0742 113.243 23.8064 112.128 21.2653C111.893 20.725 111.725 20.1205 111.637 19.4571C111.542 18.7938 111.5 17.9218 111.5 16.8358V12.7434C111.5 11.7537 111.553 10.9138 111.652 10.2237C111.752 9.53363 111.914 8.89703 112.133 8.31927C112.625 6.99257 113.441 5.9815 114.571 5.2807C115.701 4.5799 117.093 4.23218 118.736 4.23218C121.074 4.23218 122.806 4.85808 123.941 6.10989C124.469 6.68764 124.846 7.31355 125.066 7.98759C125.285 8.66164 125.395 9.47478 125.395 10.427C125.395 10.7266 125.338 10.9245 125.217 11.0315C125.097 11.1332 124.867 11.1867 124.522 11.1867H122.821C122.492 11.1867 122.267 11.1385 122.152 11.0476C122.031 10.9566 121.963 10.7694 121.948 10.4859C121.927 9.33034 121.66 8.49046 121.142 7.9769C120.624 7.46333 119.766 7.20655 118.579 7.20655C117.245 7.20655 116.303 7.59707 115.759 8.36742C115.21 9.14311 114.938 10.4538 114.938 12.3047V17.0124C114.938 18.8045 115.22 20.0991 115.785 20.8908C116.35 21.6826 117.276 22.0838 118.552 22.0838C119.703 22.0838 120.556 21.811 121.116 21.2706C121.67 20.7303 121.969 19.8904 122.005 18.751L121.995 18.7403Z"
			fill="white"
		/>
		<path
			d="M139.279 18.1617H131.285V18.3023C131.285 19.7194 131.511 20.7254 131.958 21.3204C132.406 21.9154 133.158 22.2129 134.206 22.2129C135.548 22.2129 136.364 21.7207 136.658 20.7417C136.769 20.4226 137.016 20.2603 137.4 20.2603H139.111C139.422 20.2603 139.643 20.309 139.774 20.4009C139.906 20.4929 139.969 20.6659 139.969 20.9093C139.969 21.2663 139.853 21.6882 139.622 22.1696C139.39 22.651 139.122 23.0512 138.811 23.3758C138.295 23.9221 137.632 24.344 136.811 24.636C135.995 24.9281 135.095 25.0741 134.122 25.0741C132.174 25.0741 130.669 24.5062 129.6 23.3758C128.532 22.2453 128 20.6281 128 18.5349V16.3281C128 14.1375 128.532 12.4445 129.6 11.2438C130.664 10.043 132.179 9.44263 134.148 9.44263C136.116 9.44263 137.606 9.99974 138.627 11.114C139.648 12.2282 140.158 13.8616 140.158 16.0144V17.3449C140.158 17.664 140.106 17.8858 139.995 17.994C139.885 18.1076 139.648 18.1617 139.279 18.1617ZM134.179 12.1579C133.19 12.1579 132.464 12.4283 132.016 12.9638C131.564 13.5047 131.322 14.3863 131.285 15.6141H136.827C136.827 14.4242 136.616 13.5533 136.19 12.9962C135.769 12.4391 135.095 12.1633 134.179 12.1633V12.1579Z"
			fill="white"
		/>
		<path
			d="M150.191 9.44263C151.784 9.44263 152.97 9.88793 153.753 10.7733C154.53 11.6587 154.922 12.8741 154.922 14.4091V23.4094C154.922 23.7395 154.862 23.9543 154.737 24.0538C154.612 24.1533 154.372 24.2057 154.013 24.2057H152.448C152.089 24.2057 151.844 24.1533 151.719 24.0538C151.594 23.9543 151.534 23.7395 151.534 23.4094V14.9068C151.534 14.0633 151.36 13.4189 151.007 12.9736C150.653 12.5231 150.012 12.303 149.082 12.303C148.152 12.303 147.456 12.5755 146.934 13.1098C146.412 13.6494 146.151 14.4038 146.151 15.373V23.4094C146.151 23.7395 146.091 23.9543 145.966 24.0538C145.841 24.1533 145.602 24.2057 145.238 24.2057H143.672C143.313 24.2057 143.068 24.1533 142.949 24.0538C142.823 23.9543 142.764 23.7395 142.764 23.4094V10.6214C142.764 10.2913 142.823 10.0765 142.949 9.97699C143.074 9.87745 143.313 9.82506 143.672 9.82506H145.183C145.542 9.82506 145.787 9.87745 145.912 9.97699C146.037 10.0765 146.097 10.2913 146.097 10.6214V11.1976C146.532 10.6476 147.108 10.218 147.82 9.90889C148.533 9.59979 149.327 9.44263 150.196 9.44263H150.191Z"
			fill="white"
		/>
		<path
			d="M165.527 24.2056H163.974C162.346 24.2056 161.227 23.8218 160.612 23.049C160.003 22.2813 159.695 21.244 159.695 19.9369V12.6287H158.221C157.946 12.6287 157.76 12.5769 157.665 12.4783C157.569 12.3798 157.527 12.1775 157.527 11.8663V10.7252C157.527 10.4191 157.575 10.2117 157.665 10.1131C157.755 10.0146 157.94 9.96271 158.221 9.96271H159.695V6.75727C159.695 6.4305 159.754 6.21784 159.876 6.11929C159.997 6.02074 160.231 5.96887 160.581 5.96887H162.108C162.457 5.96887 162.696 6.02074 162.813 6.11929C162.935 6.21784 162.993 6.4305 162.993 6.75727V9.96271H165.517C165.792 9.96271 165.978 10.0146 166.073 10.1131C166.163 10.2117 166.211 10.414 166.211 10.7252V11.8663C166.211 12.1723 166.163 12.3798 166.073 12.4783C165.983 12.5769 165.797 12.6287 165.517 12.6287H162.993V19.4442C162.993 20.1133 163.11 20.606 163.338 20.9224C163.571 21.2388 163.963 21.3996 164.52 21.3996H165.517C165.792 21.3996 165.978 21.4515 166.073 21.55C166.163 21.6486 166.211 21.8508 166.211 22.1621V23.438C166.211 23.744 166.169 23.9515 166.084 24.05C165.999 24.1486 165.808 24.2005 165.517 24.2005L165.527 24.2056Z"
			fill="white"
		/>
		<path
			d="M172.004 11.081V12.3524C172.311 11.7699 172.774 11.2836 173.393 10.8936C174.011 10.5035 174.661 10.311 175.353 10.311H175.951C176.221 10.311 176.403 10.3617 176.497 10.4579C176.585 10.5542 176.632 10.7517 176.632 11.0557V12.4892C176.632 12.7881 176.585 12.9907 176.497 13.0869C176.403 13.1832 176.227 13.2338 175.951 13.2338H175.187C174.261 13.2338 173.507 13.5276 172.924 14.1203C172.342 14.713 172.051 15.493 172.051 16.4656V23.4408C172.051 23.7397 171.999 23.9474 171.89 24.0487C171.78 24.15 171.546 24.2057 171.182 24.2057H169.685C169.342 24.2057 169.108 24.1551 168.993 24.0487C168.874 23.9423 168.816 23.7397 168.816 23.4408V11.081C168.816 10.7821 168.874 10.5744 168.993 10.4731C169.113 10.3668 169.342 10.3161 169.685 10.3161H171.13C171.494 10.3161 171.728 10.3718 171.838 10.4731C171.947 10.5795 171.999 10.7821 171.999 11.081H172.004Z"
			fill="white"
		/>
		<path
			d="M183.753 15.4338C184.157 15.4338 184.622 15.4501 185.142 15.4774C185.663 15.5046 186.153 15.5482 186.612 15.6081V14.8674C186.612 13.7998 186.42 13.0646 186.041 12.6561C185.663 12.2476 185.077 12.0406 184.284 12.0406C183.738 12.0406 183.289 12.1332 182.935 12.3129C182.581 12.4927 182.334 12.8031 182.192 13.2389C182.122 13.4676 182.036 13.6147 181.93 13.68C181.824 13.7454 181.632 13.7781 181.349 13.7781H179.631C179.349 13.7781 179.157 13.7236 179.051 13.6201C178.944 13.5166 178.894 13.3587 178.894 13.1517C178.894 12.411 179.167 11.7193 179.712 11.0711C180.152 10.5592 180.733 10.1561 181.46 9.8729C182.182 9.58968 183.127 9.44263 184.289 9.44263C185.683 9.44263 186.764 9.64415 187.542 10.0417C188.32 10.4393 188.865 11.0276 189.183 11.7955C189.501 12.5635 189.658 13.5112 189.658 14.6332V20.1941C189.658 21.0328 189.507 21.8226 189.209 22.5742C188.911 23.3258 188.39 23.9304 187.663 24.3879C186.93 24.8454 185.905 25.0741 184.582 25.0741H183.839C181.97 25.0741 180.591 24.6983 179.702 23.9467C178.813 23.1951 178.369 21.986 178.369 20.3084C178.369 19.0557 178.611 18.0754 179.096 17.3728C179.581 16.6702 180.233 16.1691 181.056 15.875C181.874 15.5809 182.778 15.4338 183.768 15.4338H183.753ZM183.91 18.0263C183.081 18.0263 182.46 18.1952 182.046 18.5383C181.632 18.8815 181.425 19.4588 181.425 20.2758C181.425 21.0927 181.627 21.6646 182.031 21.986C182.435 22.3073 183.011 22.4707 183.748 22.4707H184.198C185.061 22.4707 185.678 22.242 186.052 21.7844C186.42 21.3269 186.607 20.559 186.607 19.4751V18.1897C186.026 18.1135 185.511 18.0645 185.072 18.0481C184.632 18.0318 184.243 18.0209 183.91 18.0209V18.0263Z"
			fill="white"
		/>
		<path
			d="M196.606 5.87947V23.4271C196.606 23.7499 196.544 23.96 196.416 24.0573C196.288 24.1547 196.042 24.2059 195.669 24.2059H194.063C193.695 24.2059 193.444 24.1547 193.321 24.0573C193.193 23.96 193.132 23.7499 193.132 23.4271V5.87947C193.132 5.55669 193.193 5.34663 193.321 5.24929C193.45 5.15194 193.695 5.10071 194.063 5.10071H195.669C196.037 5.10071 196.288 5.15194 196.416 5.24929C196.544 5.34663 196.606 5.55669 196.606 5.87947Z"
			fill="white"
		/>
		<path
			d="M202.25 19.8639C202.911 19.8639 203.438 20.0629 203.832 20.4611C204.227 20.8592 204.421 21.4036 204.421 22.1003V22.838C204.421 23.5347 204.227 24.0791 203.832 24.4772C203.438 24.8753 202.911 25.0744 202.25 25.0744C201.59 25.0744 201.062 24.8753 200.668 24.4772C200.274 24.0791 200.079 23.5347 200.079 22.838V22.1003C200.079 21.4036 200.274 20.8592 200.668 20.4611C201.062 20.0629 201.59 19.8639 202.25 19.8639Z"
			fill="white"
		/>
		<path
			d="M209.62 8.11535C209.097 8.11535 208.675 7.96802 208.365 7.67336C208.05 7.37871 207.895 6.97355 207.895 6.46316V5.88437C207.895 5.35293 208.05 4.94251 208.365 4.65838C208.68 4.37425 209.097 4.23218 209.62 4.23218C210.143 4.23218 210.589 4.37425 210.899 4.65838C211.214 4.94251 211.369 5.35293 211.369 5.88437V6.46316C211.369 6.97881 211.214 7.38397 210.899 7.67336C210.584 7.96802 210.158 8.11535 209.62 8.11535ZM211.117 10.5673V23.406C211.117 23.7375 211.063 23.9532 210.952 24.0532C210.841 24.1532 210.627 24.2058 210.303 24.2058H208.908C208.588 24.2058 208.37 24.1532 208.263 24.0532C208.152 23.9532 208.099 23.7375 208.099 23.406V10.5673C208.099 10.2358 208.152 10.0201 208.263 9.92013C208.375 9.82016 208.588 9.76754 208.908 9.76754H210.303C210.623 9.76754 210.841 9.82016 210.952 9.92013C211.063 10.0201 211.117 10.2358 211.117 10.5673Z"
			fill="white"
		/>
		<path
			d="M220.921 25.0741C219.009 25.0741 217.521 24.5224 216.448 23.419C215.379 22.3156 214.842 20.7687 214.842 18.7891V16.0144C214.842 13.9374 215.379 12.3201 216.448 11.168C217.516 10.016 219.009 9.44263 220.921 9.44263C222.833 9.44263 224.321 9.99433 225.395 11.0977C226.463 12.2011 227 13.7481 227 15.7277V18.5024C227 20.5632 226.463 22.1696 225.395 23.3325C224.326 24.4954 222.833 25.0741 220.921 25.0741ZM220.921 22.2183C222.849 22.2183 223.815 20.9364 223.815 18.3672V16.1604C223.815 13.5912 222.854 12.3093 220.921 12.3093C218.989 12.3093 218.027 13.5966 218.027 16.1604V18.3672C218.027 20.9364 218.989 22.2183 220.921 22.2183Z"
			fill="white"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint5_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint6_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint7_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint8_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint9_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint10_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint11_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint12_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint13_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint14_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint15_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint16_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint17_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint18_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint19_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint20_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint21_linear_1_263"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint22_linear_1_263"
				x1="13.9229"
				y1="10.0109"
				x2="13.9229"
				y2="20.0765"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#59ACFF" stop-opacity="0.51" />
			</linearGradient>
		</defs>
	</svg>
</template>
