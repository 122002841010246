<template>
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4.31579 11.6842V8.73684M8 11.6842V4.31579M11.6842 11.6842V7.26316M1 8C1 4.70017 1 3.05026 2.02512 2.02512C3.05026 1 4.70017 1 8 1C11.2998 1 12.9497 1 13.9749 2.02512C15 3.05026 15 4.70017 15 8C15 11.2998 15 12.9497 13.9749 13.9749C12.9497 15 11.2998 15 8 15C4.70017 15 3.05026 15 2.02512 13.9749C1 12.9497 1 11.2998 1 8Z"
			stroke="#999999"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
