<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import DropdownLink from '@/Components/DropdownLink.vue';

function closeOnEscape(e) {
	if (open.value && e.key === 'Escape') {
		open.value = false;
	}
}

onMounted(() => document.addEventListener('keydown', closeOnEscape));
onUnmounted(() => document.removeEventListener('keydown', closeOnEscape));

const open = ref(false);
</script>

<template>
	<!-- Full Screen Dropdown Overlay -->
	<div class="fixed inset-0 z-10" v-show="open" @click="open = false"></div>
	<div class="profile">
		<button
			class="flex items-center gap-1.5"
			type="button"
			@click="open = !open"
		>
			<!-- Get avatar from gravatar using the email address -->
			<img
				class="size-7 rounded-full"
				:src="`//unavatar.io/${$page.props.auth.user.email}`"
				alt="User Avatar"
			/>
			<span class="hidden font-medium lg:block">
				{{ $page.props.auth.business_name }}
			</span>
			<i class="ri-arrow-down-s-line text-xl"></i>
		</button>

		<Transition
			enter-active-class="transition ease-out duration-200"
			enter-from-class="transform opacity-0 scale-95"
			enter-to-class="transform opacity-100 scale-100"
			leave-active-class="transition ease-in duration-150"
			leave-from-class="transform opacity-100 scale-100"
			leave-to-class="transform opacity-0 scale-95"
		>
			<ul class="divide-y divide-gray-400/10" v-show="open">
				<li class="px-4 py-3">
					<p class="text-sm">Signed in as</p>
					<p class="truncate text-sm font-medium text-gray-300">
						{{ $page.props.auth.user.email }}
					</p>
				</li>
				<li>
					<DropdownLink href="/account">
						<i class="ri-user-line"></i>
						Account details
					</DropdownLink>
				</li>
				<li>
					<DropdownLink href="/logout" method="post" as="button" class="text-red-500">
						<i class="ri-logout-circle-r-line"></i>
						Sign Out
					</DropdownLink>
				</li>
			</ul>
		</Transition>
	</div>
</template>
