<template>
	<svg
		width="29"
		height="34"
		viewBox="0 0 29 34"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.5494 23.7897L8.87847 19.9234C7.7978 18.8046 7.08786 17.3259 6.96509 15.6625C6.68506 11.8686 9.56891 8.55332 13.3951 8.27091C17.2212 7.9885 20.5691 10.8466 20.8492 14.6419C21.1293 18.4372 18.2454 21.7511 14.4192 22.0335C12.7932 22.1535 11.2554 21.7058 9.99971 20.8604L6.65386 24.7433C6.38099 25.0599 5.94179 25.0923 5.62538 24.8192C5.30898 24.546 5.27653 24.1064 5.5494 23.7897ZM20.0727 14.6992C19.8245 11.3366 16.8568 8.80936 13.4616 9.05996C10.0664 9.31056 7.50209 12.2475 7.75018 15.6087C7.99827 18.9699 10.966 21.4986 14.3612 21.248C17.7564 20.9974 20.3204 18.0563 20.0727 14.6992Z"
			fill="#408DDC"
		/>
		<path
			d="M23.0208 3.54562C23.0208 4.42079 23.0505 5.29739 23.0095 6.16971C22.9855 6.69112 23.1268 6.87156 23.6711 6.85451C24.9166 6.81473 26.1663 6.86729 27.4118 6.83035C27.9335 6.81473 27.9689 7.06193 27.9674 7.4711C27.966 7.89022 27.9674 8.17863 27.392 8.16584C25.6984 8.1289 24.0033 8.13885 22.3097 8.16158C21.8516 8.16726 21.7018 8.03229 21.706 7.56345C21.7258 5.29312 21.7315 3.02279 21.6975 0.753888C21.6891 0.185595 21.9563 0.178491 22.3804 0.164284C22.8469 0.150077 23.0561 0.25379 23.0335 0.780881C22.9925 1.70152 23.0222 2.62499 23.0222 3.54704H23.0194L23.0208 3.54562Z"
			fill="url(#paint0_linear_1_2369)"
		/>
		<path
			d="M6.48145 4.15659C6.48145 5.31449 6.46872 6.47238 6.48852 7.63028C6.49559 8.0295 6.36411 8.16163 5.96544 8.15879C4.22513 8.14174 2.48483 8.1318 0.744532 8.16163C0.163489 8.17158 0.26245 7.82066 0.244072 7.47116C0.221452 7.06483 0.269519 6.81336 0.794013 6.82899C1.99286 6.86592 3.19453 6.79773 4.39054 6.8574C5.07337 6.8915 5.20344 6.64145 5.19071 6.01917C5.15113 4.27167 5.19495 2.52133 5.16244 0.77383C5.15254 0.241056 5.36319 0.152971 5.82265 0.162916C6.25101 0.17144 6.52245 0.188489 6.50124 0.75394C6.45741 1.88626 6.48852 3.02143 6.48852 4.15659H6.48145Z"
			fill="url(#paint1_linear_1_2369)"
		/>
		<path
			d="M15.0784 27.083C15.0784 25.783 15.0982 24.483 15.0685 23.1831C15.0572 22.6858 15.1505 22.467 15.716 22.4755C16.2391 22.4841 16.396 22.6219 16.3903 23.1575C16.3635 25.7105 16.3804 28.2622 16.3762 30.8152C16.3762 31.1974 16.4073 31.5256 16.6886 31.8524C17.1128 32.3453 17.0209 33.1125 16.5798 33.5274C16.1288 33.9508 15.3611 33.9593 14.9003 33.5459C14.4535 33.1438 14.3433 32.361 14.7575 31.8737C15.0346 31.5483 15.0855 31.2258 15.0826 30.8394C15.0727 29.5863 15.0798 28.3346 15.0798 27.0815L15.0784 27.083Z"
			fill="url(#paint2_linear_1_2369)"
		/>
		<path
			d="M9.84335 26.32C9.84335 27.0986 9.83911 27.8771 9.84477 28.6571C9.84901 29.2581 9.80235 29.8306 10.2307 30.3875C10.6167 30.8891 10.4145 31.4773 9.931 31.8978C9.51395 32.2601 8.79578 32.2459 8.36742 31.8935C7.89099 31.5014 7.71286 30.7953 8.11153 30.3108C8.47204 29.8718 8.46921 29.42 8.46921 28.9299C8.46921 26.9948 8.49324 25.0584 8.45507 23.1248C8.44376 22.5394 8.68692 22.4883 9.16193 22.4826C9.6525 22.4769 9.88435 22.5622 9.8589 23.1347C9.81225 24.1946 9.84477 25.2587 9.84477 26.32H9.84335Z"
			fill="url(#paint3_linear_1_2369)"
		/>
		<path
			d="M13.1079 28.1855C13.1079 29.9088 13.0994 31.6336 13.1136 33.3569C13.1178 33.7959 13.0358 33.9096 12.6117 33.6652C12.0844 33.3612 11.8031 33.0273 11.8144 32.3383C11.8624 29.2922 11.8426 26.2462 11.8285 23.2001C11.8257 22.737 11.8582 22.4784 12.4505 22.4713C13.0698 22.4642 13.1221 22.7284 13.115 23.2257C13.0924 24.878 13.1065 26.5317 13.1065 28.1841L13.1079 28.1855Z"
			fill="url(#paint4_linear_1_2369)"
		/>
		<path
			d="M19.0427 7.98404C18.4659 8.11759 18.3231 7.85901 18.3386 7.30493C18.3754 6.03195 18.35 4.75614 18.3485 3.48174C18.3485 2.87793 18.391 2.30111 17.9414 1.76266C17.5809 1.32933 17.8 0.593396 18.2439 0.241055C18.6652 -0.094238 19.4937 -0.0743478 19.8867 0.279414C20.3179 0.667274 20.4691 1.42452 20.0818 1.88484C19.7496 2.2798 19.7439 2.68187 19.7439 3.12798C19.7467 4.5203 19.7199 5.91403 19.7552 7.30493C19.7693 7.8647 19.6152 8.1119 19.0441 7.98404H19.0427Z"
			fill="url(#paint5_linear_1_2369)"
		/>
		<path
			d="M18.3682 31.7585C18.3682 28.6741 18.3739 25.7972 18.364 22.9188C18.3626 22.3476 18.7613 22.5138 19.0539 22.5025C19.3649 22.4911 19.7254 22.3917 19.7212 22.9457C19.7056 25.3795 19.7325 27.8132 19.7028 30.2469C19.6943 31.007 18.9224 31.1689 18.3668 31.7585H18.3682Z"
			fill="url(#paint6_linear_1_2369)"
		/>
		<path
			d="M12.4545 7.98406C11.8876 8.11051 11.783 7.82778 11.7915 7.32484C11.8169 5.86149 11.7943 4.39814 11.7999 2.93621C11.7999 2.59807 11.7505 2.31535 11.5172 2.02978C11.0803 1.49416 11.1553 0.729811 11.6162 0.30075C12.0374 -0.0927924 12.8744 -0.101317 13.2971 0.282281C13.7961 0.735494 13.864 1.49274 13.389 2.06246C13.167 2.32955 13.1274 2.59097 13.1274 2.90211C13.1317 4.36546 13.1105 5.82881 13.1373 7.29075C13.1472 7.81358 13.0553 8.1304 12.4531 7.98548L12.4545 7.98406Z"
			fill="url(#paint7_linear_1_2369)"
		/>
		<path
			d="M4.50242 11.5358C3.98641 11.5358 3.46898 11.5443 2.95297 11.533C2.5953 11.5259 2.29417 11.5727 1.98739 11.8285C1.46714 12.2646 0.775826 12.1751 0.334742 11.7049C-0.0950319 11.2446 -0.11341 10.5029 0.293744 10.0213C0.695243 9.54678 1.43321 9.41181 1.94498 9.82525C2.29559 10.108 2.64336 10.1478 3.04628 10.1449C4.1504 10.1364 5.25452 10.1733 6.35723 10.1293C6.9722 10.1051 6.88738 10.4717 6.9001 10.8524C6.91424 11.2644 6.9199 11.5955 6.33461 11.5472C5.72812 11.4974 5.11456 11.5358 4.50383 11.5358H4.50242Z"
			fill="url(#paint8_linear_1_2369)"
		/>
		<path
			d="M9.83919 4.0855C9.83919 5.19509 9.81091 6.3061 9.8505 7.41427C9.87029 7.96835 9.61017 8.02518 9.17191 8.00813C8.78031 7.99251 8.45091 8.04933 8.45939 7.44979C8.48908 5.20787 8.48766 2.96454 8.45656 0.722625C8.44808 0.114552 8.78879 0.18843 9.17332 0.171381C9.60734 0.151491 9.87595 0.199796 9.85474 0.759564C9.81374 1.86773 9.84202 2.97875 9.84202 4.08834H9.83919V4.0855Z"
			fill="url(#paint9_linear_1_2369)"
		/>
		<path
			d="M4.56607 18.1934C4.0953 18.1934 3.62453 18.2047 3.15517 18.1905C2.81587 18.1806 2.53596 18.2545 2.24614 18.4676C1.66086 18.8952 0.94551 18.7929 0.532701 18.2942C0.121305 17.7956 0.155235 17.088 0.613283 16.6206C1.09254 16.1319 1.71882 16.0921 2.31966 16.5794C2.56989 16.7826 2.81305 16.811 3.09721 16.8096C4.17871 16.8067 5.26304 16.8422 6.34313 16.7954C6.94538 16.7698 6.89307 17.1093 6.89873 17.5043C6.90438 17.9149 6.91852 18.253 6.33041 18.2047C5.74654 18.1564 5.1556 18.1948 4.56749 18.1948L4.56607 18.1934Z"
			fill="url(#paint10_linear_1_2369)"
		/>
		<path
			d="M23.6246 13.5064C24.1194 13.5064 24.6142 13.4993 25.109 13.5092C25.5133 13.5163 25.8653 13.4751 26.2145 13.1825C26.7221 12.7562 27.542 12.8926 27.9308 13.3757C28.3224 13.863 28.3026 14.5322 27.8813 15.001C27.4346 15.4983 26.7164 15.5679 26.1735 15.1047C25.912 14.8802 25.6533 14.8291 25.338 14.8305C24.1844 14.8376 23.0294 14.8035 21.8758 14.8461C21.2919 14.8674 21.3075 14.5563 21.3046 14.1443C21.3032 13.7408 21.3301 13.4595 21.8574 13.4979C22.4427 13.5405 23.035 13.5078 23.6232 13.5078L23.6246 13.5064Z"
			fill="url(#paint11_linear_1_2369)"
		/>
		<path
			d="M16.3735 4.11969C16.3735 5.20655 16.348 6.29483 16.3848 7.38169C16.4017 7.88747 16.2335 8.02954 15.7458 8.01392C15.3103 8.00113 15.0644 7.97129 15.0714 7.41721C15.1011 5.19519 15.1011 2.97316 15.0672 0.75256C15.0587 0.187109 15.3202 0.177164 15.7458 0.162956C16.2264 0.145908 16.406 0.275194 16.389 0.788078C16.3508 1.89767 16.3777 3.0101 16.3777 4.11969H16.3735Z"
			fill="url(#paint12_linear_1_2369)"
		/>
		<path
			d="M23.3248 21.4894C22.8625 21.4894 22.509 21.4894 22.157 21.4894C21.2621 21.4894 21.2833 21.4894 21.3088 20.5887C21.3201 20.2051 21.4643 20.1014 21.8135 20.1113C22.4483 20.1284 23.083 20.1184 23.7178 20.1156C24.2946 20.1128 24.8474 20.1639 25.3747 19.7405C25.8172 19.3853 26.6541 19.6382 26.9552 20.073C27.3016 20.5702 27.2465 21.2934 26.8336 21.7025C26.4025 22.1302 25.6914 22.2282 25.2319 21.8063C24.6028 21.228 23.8775 21.6329 23.3248 21.4894Z"
			fill="url(#paint13_linear_1_2369)"
		/>
		<path
			d="M24.5632 11.5273C23.6457 11.5273 22.7253 11.4988 21.8092 11.5372C21.2254 11.5614 21.313 11.1948 21.3116 10.8439C21.3116 10.4915 21.231 10.1406 21.8106 10.1491C23.6937 10.1761 25.5768 10.1776 27.4599 10.1491C28.0395 10.1406 27.9575 10.493 27.9575 10.8453C27.9575 11.1962 28.041 11.5599 27.4585 11.5372C26.4943 11.4988 25.5288 11.5273 24.5632 11.5273Z"
			fill="url(#paint14_linear_1_2369)"
		/>
		<path
			d="M25.4709 23.4843C25.1825 24.5214 24.6439 25.04 23.6104 24.851C23.1609 24.7686 22.9375 24.9675 23.011 25.4364C23.0845 25.9052 22.8315 26.4124 23.2075 26.85C23.3107 26.9708 23.134 27.1029 23.0478 27.2038C22.6689 27.6499 22.2815 28.0917 21.9083 28.5236C21.6326 28.4213 21.733 28.2267 21.7316 28.0875C21.7231 26.742 21.7316 25.398 21.7231 24.0526C21.7217 23.7144 21.7302 23.463 22.1911 23.4743C23.2683 23.5013 24.3456 23.4829 25.4695 23.4829L25.4709 23.4843Z"
			fill="url(#paint15_linear_1_2369)"
		/>
		<path
			d="M3.22294 2.53697C3.22294 3.15214 3.20739 3.7659 3.22718 4.37965C3.23849 4.73626 3.13812 4.89254 2.75358 4.87833C2.07217 4.85276 1.38792 4.84565 0.707916 4.87833C0.0943573 4.90817 0.264005 4.48053 0.248454 4.16228C0.230075 3.80852 0.169285 3.47039 0.744673 3.46612C1.83042 3.45618 1.829 3.42208 1.829 2.31391C1.829 1.74704 1.84173 1.17875 1.82618 0.611876C1.80921 -0.0146664 2.26867 0.201285 2.57263 0.182815C2.87941 0.164346 3.28515 0.0634737 3.22294 0.621821C3.22011 0.644553 3.22294 0.668705 3.22294 0.692858C3.22294 1.30803 3.22294 1.92179 3.22294 2.53697Z"
			fill="url(#paint16_linear_1_2369)"
		/>
		<path
			d="M26.3699 1.87342C26.3699 1.99134 26.3699 2.10926 26.3699 2.22718C26.3699 3.38792 26.3685 3.41776 27.5122 3.46748C28.1243 3.49448 27.942 3.89938 27.9533 4.21336C27.9646 4.52024 28.0763 4.90242 27.5164 4.87827C26.8364 4.84843 26.1522 4.85696 25.4708 4.87827C25.1145 4.88963 24.9703 4.7845 24.976 4.4009C24.9944 3.14924 25.0042 1.89615 24.9717 0.644489C24.9562 0.0307327 25.3619 0.189855 25.6814 0.178489C26.025 0.167123 26.4222 0.0804583 26.3756 0.668641C26.3445 1.06787 26.3699 1.47135 26.3699 1.87342Z"
			fill="url(#paint17_linear_1_2369)"
		/>
		<path
			d="M2.72536 23.4828C3.84504 23.4828 4.90109 23.4985 5.95715 23.4757C6.35723 23.4672 6.48447 23.6022 6.47882 24.0014C6.46044 25.4875 6.47175 26.975 6.47175 28.5747C5.82284 28.13 5.29977 27.6058 5.06226 27.0006C4.93502 26.6766 5.16263 26.0956 5.2008 25.6224C5.25028 24.9874 5.06226 24.743 4.38791 24.77C3.23996 24.8183 3.223 24.7672 2.72536 23.4814V23.4828Z"
			fill="url(#paint18_linear_1_2369)"
		/>
		<path
			d="M3.57199 14.8148C2.63044 14.8148 1.68749 14.7864 0.747356 14.8248C0.162072 14.8489 0.262447 14.4838 0.24831 14.1343C0.234172 13.772 0.239827 13.5078 0.745943 13.5149C2.62903 13.5404 4.51353 13.5433 6.39662 13.5149C6.98049 13.5063 6.88153 13.8573 6.89567 14.2053C6.9098 14.5676 6.90556 14.8433 6.39804 14.8248C5.4579 14.7893 4.51495 14.8148 3.5734 14.8148H3.57199Z"
			fill="url(#paint19_linear_1_2369)"
		/>
		<path
			d="M24.5448 16.8124C25.4157 16.8124 26.2879 16.8081 27.1588 16.8152C27.4005 16.8167 27.7427 16.7357 27.6847 17.1647C27.6366 17.5256 27.8006 18.0868 27.204 18.0982C25.3676 18.1323 23.5297 18.1152 21.6933 18.1095C21.1236 18.1081 21.3554 17.6549 21.3243 17.375C21.2918 17.0951 21.2664 16.7996 21.7188 16.8081C22.6603 16.828 23.6033 16.8138 24.5448 16.8138V16.8124Z"
			fill="url(#paint20_linear_1_2369)"
		/>
		<path
			d="M1.20982 20.1995C3.05191 20.1995 4.72435 20.2194 6.39396 20.1895C6.97925 20.1796 6.8718 20.5376 6.89018 20.8843C6.90998 21.2594 6.87887 21.5051 6.38124 21.4938C4.96892 21.4625 3.55519 21.4881 2.14146 21.481C1.28757 21.4767 1.5166 20.7138 1.2084 20.1995H1.20982Z"
			fill="url(#paint21_linear_1_2369)"
		/>
		<path
			d="M11.0723 15.955C11.0723 15.955 11.7502 15.955 12.654 17.7165C12.654 17.7165 15.1661 13.1031 17.3992 12.1804"
			stroke="url(#paint22_linear_1_2369)"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint5_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint6_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint7_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint8_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint9_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint10_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint11_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint12_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint13_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint14_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint15_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint16_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint17_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint18_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint19_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint20_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint21_linear_1_2369"
				x1="14.1057"
				y1="0"
				x2="14.1057"
				y2="33.8505"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#0BABF0" />
			</linearGradient>
			<linearGradient
				id="paint22_linear_1_2369"
				x1="13.9229"
				y1="10.0109"
				x2="13.9229"
				y2="20.0765"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C53FA7" />
				<stop offset="1" stop-color="#59ACFF" stop-opacity="0.51" />
			</linearGradient>
		</defs>
	</svg>
</template>
