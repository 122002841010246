export default {
	beforeMount(el, binding) {
		el.clickOutsideEvent = function (event) {
			// Check that the click was outside the el and its children, and the handler is a function
			if (
				!(el === event.target || el.contains(event.target)) &&
				typeof binding.value === 'function'
			) {
				binding.value(event); // execute the provided function
			}
		};
		document.addEventListener('click', el.clickOutsideEvent);
	},
	unmounted(el) {
		document.removeEventListener('click', el.clickOutsideEvent);
	},
};
