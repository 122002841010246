import './bootstrap';
import '../css/app.css';
import { createApp, h } from 'vue';
import * as Sentry from '@sentry/vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { createInertiaApp } from '@inertiajs/vue3';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import ClickOutsideDirective from './Directives/ClickOutsideDirective';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) => {
		const page = resolvePageComponent(
			`./Pages/${name}.vue`,
			import.meta.glob('./Pages/**/*.vue')
		);
		page.then(
			(module) =>
				(module.default.layout = name.startsWith('Auth/')
					? GuestLayout
					: AuthenticatedLayout)
		);
		return page;
	},
	setup({ el, App, props, plugin }) {
		const app = createApp({ render: () => h(App, props) });

		Sentry.init({
			app,
			dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
			environment: import.meta.env.MODE,
		});

		app
			.use(plugin)
			.use(Toast, {})
			.directive('click-out', ClickOutsideDirective)
			.mount(el);

		return app;
	},
	progress: {
		color: '#6A69F5',
	},
});
